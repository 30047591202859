import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication, ServerResponseType } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

export function MSALInstanceFactory() {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: `${window.location.origin}/login`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return <MsalInterceptorConfiguration>{
    interactionType: InteractionType.Redirect,
  };
}

export function initializeMsalInstance(msalService: MsalService): () => Promise<void> {
  return async () => {
    await msalService.instance.initialize();
  };
}

export function setRedirectUrl() {
  if (!location.pathname.includes('/login')) {
    const previousUrl = localStorage.getItem('previousUrl');
    if (previousUrl === location.pathname) {
      localStorage.setItem('redirectUrl', '/dashboard');
    } else {
      localStorage.setItem('redirectUrl', location.pathname);
    }
  }
}
